import $ from 'jquery';
import mustache from 'mustache';
import { RemindersModal } from '../modals/reminders_modal';
import { BasePage } from './base_page';

export class WorkOrdersFormPage extends BasePage {
  // @override
  setup () {
    this.filterByOfficeType();

    /**
     * Notes
     */
    $('form.new_note').on('ajax:success', (event, data) => {
      let note = event.detail[0];
      const template = $('.js-note-tmpl').html();

      $('[name="note[message]"]').val(null);
      $('.js-work_order-notes').prepend(mustache.render(template, { note: note }));
    });

    $('.js-work_order-notes').on('click', '.js-reminders__add', (event) => {
      event.preventDefault();
      const $link = $(event.currentTarget);
      const url = $link.attr('href');
      RemindersModal.start(url);
    });

    const toggleWarrantyFields = () => {
      const type = $('#work_order_work_order_type option:selected').text();
      if (type === 'Warranty Repairs') {
        $('.warranty-only').removeClass('d-none');
      } else {
        $('#work_order_manager_id').val('');
        $('#work_order_issue_reported_date').val('');
        $('.warranty-only').addClass('d-none');
      }
    };

    const togglePermitFields = () => {
      const type = $('#work_order_work_order_type option:selected').text();
      if (type === 'Permits') {
        $('.permits-only').removeClass('d-none');
      } else {
        $('#work_order_mid_inspect_scheduled_date').val('');
        $('#work_order_mid_inspect_result').val('');
        $('#work_order_final_inspect_scheduled_date').val('');
        $('#work_order_final_inspect_result').val('');
        $('.permits-only').addClass('d-none');
      }
    };

    toggleWarrantyFields();
    togglePermitFields();

    $('#work_order_work_order_type').on('change', (e) => {
      toggleWarrantyFields();
      togglePermitFields();

      const workOrderTagSelect = document.querySelector('#work_order_work_order_tag_ids');
      const ts = workOrderTagSelect.tomselect;

      ts.clear();
      ts.clearOptions();

      if (!e.target.value) {
        $('#work_order_work_order_status_id').empty();

        return;
      }
      const url = `/work_order_types/${e.target.value}/statuses_and_tags`;
      $.ajax({
        type: 'GET',
        dataType: 'json',
        url,
        success: (data) => {
          $('#work_order_work_order_status_id').val('');
          $('#work_order_work_order_status_id').empty();
          data.statuses.forEach((option) => $('#work_order_work_order_status_id').append(new Option(option[0], option[1])));

          data.tags.forEach((option) => {
            ts.addOption({ value: option[1], text: option[0] });
          });
        },
        error: () => (window.alert('Work Order Statuses could not be loaded! Please refresh the page and try again.'))
      });
    });

    $('#work_order_active_leak').on('change', (e) => {
      if (e.target.checked) {
        $('#work_order_priority').val('high');
        $('#work_order_priority').attr('disabled', true);
      } else {
        $('#work_order_priority').attr('disabled', false);
      }
    });
  }

  filterByOfficeType () {
    $('#work_order').on('change', (event) => {
      var selected = $('select#work_order :selected').val();
      if (selected === '') {
        $('.js--filter-work_order').show();
        $('.js-accounting-filter-work_order').show();
        $('.js-sales-filter-work_order').show();
        $('.js-csr-filter-work_order').show();
        $('.js-production-filter-work_order').show();
      } else if (selected === 'production') {
        $('.js-production-filter-work_order').show();
        $('.js-accounting-filter-work_order').hide();
        $('.js-sales-filter-work_order').hide();
        $('.js-csr-filter-work_order').hide();
        $('.js--filter-work_order').hide();
      } else if (selected === 'accounting') {
        $('.js-accounting-filter-work_order').show();
        $('.js-production-filter-work_order').hide();
        $('.js-sales-filter-work_order').hide();
        $('.js-csr-filter-work_order').hide();
        $('.js--filter-work_order').hide();
      } else if (selected === 'sales') {
        $('.js-sales-filter-work_order').show();
        $('.js-production-filter-work_order').hide();
        $('.js-accounting-filter-work_order').hide();
        $('.js-csr-filter-work_order').hide();
        $('.js--filter-work_order').hide();
      } else if (selected === 'csr') {
        $('.js-csr-filter-work_order').show();
        $('.js-production-filter-work_order').hide();
        $('.js-sales-filter-work_order').hide();
        $('.js-accounting-filter-work_order').hide();
        $('.js--filter-work_order').hide();
      }
    });
  }
}
