import $ from 'jquery';
import swal from 'sweetalert';
import moment from 'moment';

$(document).ready(() => {
  $('#client_address_selector').on('change', (event) => {
    const selectedData = event.target.options[event.target.selectedIndex].dataset;
    const setFields = () => {
      $('#project_address_1').val(selectedData.address);
      $('#project_address_2').val(selectedData.address2);
      $('#project_city').val(selectedData.city);
      document.getElementById('project_state').tomselect.setValue(selectedData.state);
      $('#project_zipcode').val(selectedData.zipcode);
    };
    if (
      !!$('#project_address_1').val() ||
      !!$('#project_address_2').val() ||
      !!$('#project_city').val() ||
      !!$('#project_state').val() ||
      !!$('#project_zipcode').val()
    ) {
      swal({
        title: 'Are you sure?',
        text: 'This will override the entered address',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then((accepted) => {
        if (accepted) {
          setFields();
        }
      });
    } else {
      setFields();
    }
    event.target.value = '';
  });

  $('#project_production_status_id, #project_office_status_id, #project_accounting_status_id').on('change', () => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      data: {
        production_status_id: $('#project_production_status_id').val(),
        office_status_id: $('#project_office_status_id').val(),
        accounting_status_id: $('#project_accounting_status_id').val(),
        lead_flag: $('#project_lead_flag').val()
      },
      url: '/project_tags/options_by_status',
      success: (data) => {
        const projectTagSelect = document.querySelector('#project_project_tag_ids');
      
        const ts = projectTagSelect.tomselect;
        const tagVal = ts.getValue();
        ts.clearOptions();
        const optgroups = {};
        data.tags.forEach(optionGroup => {
          optgroups[optionGroup[0]] = true;
        });

        Object.keys(optgroups).forEach(groupName => {
          ts.addOptionGroup(groupName, { label: groupName });
        });

        data.tags.forEach((optionGroup) => {
          optionGroup[1].forEach((option) => {
            ts.addOption({
              value: option[1],
              text: option[0],
              optgroup: optionGroup[0]
            });

            if (tagVal.includes(option[1])) {
              ts.addItem(option[1]);
            }
          });
        });
      }
    });
  });

  $('#project_build_date').on('change', () => {
    if ($('#project_material_delivery_date').val() && $('#project_material_delivery_date').data('manualChange')) {
      $('#delivery-days-warning').removeClass('d-none');
    } else {
      const buildDate = moment($('#project_build_date').val());
      const weekday = buildDate.isoWeekday();
      buildDate.subtract(weekday === 1 ? 3 : (weekday === 2 ? 4 : 2), 'days');
      $('#project_material_delivery_date').val(buildDate.format('YYYY-MM-DD'));
    }
  });

  $('#project_material_delivery_date').on('change', () => {
    $('#project_material_delivery_date').data('manualChange', true);
  });

  $('#project_has_tenant').on('change', () => {
    if ($('#project_has_tenant').is(':checked')) {
      $('.tenant-info').removeClass('d-none');
    } else {
      $('.tenant-info').addClass('d-none');
    }
  });
});
