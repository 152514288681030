import $ from 'jquery';
import { BasePage } from './base_page';

export function calculateSubTotals() {
  const $form = document.querySelector('.js-estimate__form');
  const roofTypeSelect = document.querySelector('.roof-type-select');
  const pitched_squares = $form.querySelector('#estimate_pitched_squares');
  if(pitched_squares.disabled) {
    return;
  }
  $.ajax({
    type: 'GET',
    dataType: 'json',
    data: {
      pitched_squares: $form.querySelector('#estimate_pitched_squares').value,
      pitched_waste_factor: roofTypeSelect[roofTypeSelect.selectedIndex].dataset.pitchedWasteFactor,
      flat_squares: $form.querySelector('#estimate_flat_squares').value,
      flat_waste_factor: $form.querySelector('#estimate_flat_waste_factor').value
    },
    url: '/estimates/subtotals',
    success: (data) => {
      document.querySelector('.js-estimate__subtotal_pitched_squares').value = data.subtotal_pitched_squares;
      document.querySelector('.js-estimate__subtotal_flat_squares').value = data.subtotal_flat_squares;
    }
  });
}

export class EstimatesFormPage extends BasePage {
  // @override
  setup () {
    const $form = $('.js-estimate__form').first();

    $('#estimate_pitched_squares, #estimate_roof_type, #estimate_flat_squares, #estimate_flat_waste_factor').on('change', () => {
      calculateSubTotals();
    });


    $('#estimate_pitch').on('change', () => {
      const squaresWithFactor = document.querySelector('#map-squares-with-factor');
      if (!squaresWithFactor) return;

      const factors = JSON.parse(squaresWithFactor.dataset.pitchFactors);
      const factor = Number(factors[$('#estimate_pitch').val()]);
      squaresWithFactor.innerText = (factor * $('#estimate_map_area_squares').val()).toFixed(3);
    });

    $('#shingle-selection, #modified-selection, #drip-selection').on('change', () => {
      $.ajax({
        type: 'GET',
        dataType: 'json',
        data: {
          shingle_id: $form.find('#shingle-selection select').val(),
          modified_id: $form.find('#modified-selection select').val(),
          drip_id: $form.find('#drip-selection select').val()
        },
        url: '/estimates/colors',
        success: (data) => {
          const shingleVal = $('#estimate_shingle_color_id').val();
          const modifiedVal =$('#estimate_modified_color_id').val();
          const dripEdgeVal = $('#estimate_drip_edge_color_id').val();
          $('#estimate_shingle_color_id').empty();
          $('#estimate_modified_color_id').empty();
          $('#estimate_drip_edge_color_id').empty();
          data.shingle_color_options.forEach((option) => $('#estimate_shingle_color_id').append(new Option(option[0], option[1])));
          data.modified_color_options.forEach((option) => $('#estimate_modified_color_id').append(new Option(option[0], option[1])));
          data.drip_color_options.forEach((option) => $('#estimate_drip_edge_color_id').append(new Option(option[0], option[1])));
          $('#estimate_shingle_color_id').val(shingleVal);
          $('#estimate_modified_color_id').val(modifiedVal);
          $('#estimate_drip_edge_color_id').val(dripEdgeVal);
        }
      });
    });

    const handleWarranties = () => {
      const value = $form.find('#shingle-selection select').val();
      if (!value) {
        $('#estimate_warranty_id').val('');
        $('.estimate_warranty,#shingle-manufacturer').hide();
      } else {
        $('.estimate_warranty,#shingle-manufacturer').show();
        $.ajax({
          type: 'GET',
          dataType: 'json',
          data: {
            material_id: value
          },
          url: '/estimates/warranty_options',
          success: (data) => {
            const warrantyVal = $('#estimate_warranty_id').val();
            $('#estimate_warranty_id').empty();
            data.warranty_options.forEach((option) => $('#estimate_warranty_id').append(new Option(option[0], option[1])));
            $('#estimate_warranty_id').val(warrantyVal);
            $('#shingle-manufacturer').text(`Manufacturer: ${data.manufacturer_name}`);
          }
        });
      }
    };

    handleWarranties();

    $('#shingle-selection').on('change', () => {
      handleWarranties();
    });

    $('.quantity-material-select').on('change', (event) => {
      const addButton = $(`[data-target="${event.target.id}"]`);
      if (event.target.value) {
        addButton.removeClass('disabled');
      } else {
        addButton.addClass('disabled');
      }
    });

    $('.add-quantity-button').on('click', (event) => {
      event.preventDefault();
      const link = event.target.closest('a');
      const select = $(`#${link.dataset.target}`).removeClass('d-none');

      const materialField = `#quantity_material_${select.val()}`;
      $(materialField).removeClass('d-none');
      select.find('option:selected').remove();
      select.val('');
      $(link).addClass('disabled');
    });

    $('.remove-quantity-button').on('click', (event) => {
      event.preventDefault();
      const link = event.target.closest('a');
      const targetQuantityWrapper = $(`#quantity_material_${link.dataset.materialId}`);
      const targetSelect = $(`#${link.dataset.selectTarget}`);
      targetSelect.append(new Option(link.dataset.label, link.dataset.materialId));
      targetQuantityWrapper.find('input').val('');
      targetQuantityWrapper.addClass('d-none');
    });
  }
}
